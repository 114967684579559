import axiosClient from './http'
import { AxiosRequestHeaders, AxiosResponse, Method } from 'axios'
import { setAuthInfo } from '../store/slices/authSlice'
import StorageHelper from '../utilities/StorageHelper'
import { METHOD, STORAGE_KEY } from '../constants'
import { logout } from '../store/thunks/authThunk'
import qs from 'qs'

const handleSuccess = (response, successMessage, dispatch) => {
  const { method, url } = response.config
  // console.log('==========================================================')
  // console.log(`[RESPONSE] ${method?.toUpperCase()}: ${url}\n`, response.data)
  // console.log('==========================================================')

  // successMessage &&
  // dispatch(
  //   setToast({
  //     message: t(successMessage),
  //     options: {
  //       type: ToastTypes.SUCCESS,
  //     },
  //   }),
  // );
  return response.data
}

// const loading = (dispatch: any, state: boolean) => {
//   const loadingMore = store.getState().app.loadingMore;
//   // console.log('loadingMore', loadingMore);
//   !loadingMore && dispatch(setLoading(state));
//   // console.log('loading', store.getState().app.loading);
// };

const handleError = (errorResponse, dispatch, disabledErrorMessage, returnError) => {
  console.log('==========================================================')
  console.log('[ERROR]', errorResponse)
  console.log('==========================================================')

  let message = errorResponse?.data
  if (!message) {
    message = errorResponse?.message
  } else {
    try {
      message = JSON.parse(message)
      if (
        typeof message !== 'string' &&
        typeof message === 'object' &&
        // eslint-disable-next-line no-prototype-builtins
        message?.hasOwnProperty('ko')
      ) {
        message = message.ko
      }
    } catch (e) {}
  }

  if (!disabledErrorMessage && message !== 'Unauthorized') {
    // dispatch(
    //   setToast({
    //     // message: t(`error.${errorResponse?.status || 'unknown'}`),
    //     message,
    //     options: {
    //       type: ToastTypes.ERROR,
    //     },
    //   }),
    // );
  }

  // loading(dispatch, false);
  if (returnError) {
    return errorResponse
  }
}

const httpRequest = async ({
  url,
  method,
  params,
  data,
  dispatch,
  successMessage,
  disabledErrorMessage = false,
  headers,
  returnError = false,
  onUploadProgress,
  ...rest
}) => {
  // loading(dispatch, true);
  try {
    // console.log('==========================================================')
    // console.log('[REQUEST]', `${method}: ${url}`)
    // console.log('params: ', params)
    // console.log('data: ', data)
    // console.log('==========================================================')
    const response = await axiosClient.request({
      url,
      method,
      params,
      data,
      headers,
      onUploadProgress,
      ...rest,
      paramsSerializer: {
        serialize: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
      },
    })
    // loading(dispatch, false);
    return handleSuccess(response, successMessage, dispatch)
  } catch (error) {
    const errorResponse = error?.response?.data
    // const originalRequest = error?.config;
    // console.log(JSON.stringify(errorResponse), url);
    // console.log('REQUEST_ERROR:', errorResponse.message);
    // console.log('REQUEST_ERROR:', JSON.stringify(errorResponse.data));
    // Restore expired token
    // if (
    //   errorResponse?.code === 401 &&
    //   (errorResponse?.data === 'Unauthorized' ||
    //     errorResponse?.data === 'permission denied')
    // ) {
    //   const {payload} = await dispatch(reduceRestoreToken());
    //   if (payload && payload.success) {
    //     // Request again
    //     try {
    //       const response = await axiosClient.request(
    //         originalRequest,
    //       );
    //       // loading(dispatch, false);
    //       return handleSuccess(response, successMessage, dispatch);
    //     } catch (_error) {
    //       const _errorResponse = _error?.response?.data;
    //       // console.log(JSON.stringify(_errorResponse), url);
    //       return handleError(
    //         _errorResponse,
    //         dispatch,
    //         disabledErrorMessage,
    //         returnError,
    //       );
    //     }
    //   } else {
    //     // Clear session when refresh token expired
    //     // await AsyncStorageUtils.clearStorage();
    //     // dispatch(setAuthInfo(null));
    //   }
    // }
    if (
      (errorResponse?.statusCode === 500 && errorResponse?.message === 'Not logged in.') ||
      (errorResponse?.status === 500 &&
        errorResponse?.message?.includes('JWT signature does not match locally computed signature'))
    ) {
      // StorageHelper.removeCookie(STORAGE_KEY.TOKEN)
      // localStorage.clear()
      // sessionStorage.clear()
      dispatch(setAuthInfo({}))
      // window.location.href = '/login'
      logout()
    }
    return handleError(errorResponse, dispatch, disabledErrorMessage, returnError)
  }
}

export default httpRequest
