import React from 'react'
import { CFormInput } from '@coreui/react'
import { getIn } from 'formik'
import classNames from 'classnames'

const FormikInput = (props) => {
  const {
    field,
    form,
    placeholder,
    id,
    type,
    className,
    containerClassName,
    renderContainer = true,
    ...inputProps
  } = props
  const { name } = field
  const { errors, touched, handleChange, handleBlur, values, dirty } = form
  const value = getIn(values, name)
  const isTouched = getIn(touched, name)
  const isDirty = getIn(dirty, name)
  const error = getIn(errors, name)
  const showError = !!error && (isTouched || isDirty)
  const inputElement = (
    <CFormInput
      placeholder={placeholder}
      type={type}
      id={id}
      className={className}
      invalid={showError}
      feedbackInvalid={showError ? error : undefined}
      {...field}
      {...inputProps}
    />
  )
  return !renderContainer ? (
    inputElement
  ) : (
    <div
      className={classNames([containerClassName?.includes('mb') ? '' : 'mb-3', containerClassName])}
    >
      {inputElement}
    </div>
  )
}

export default FormikInput
