import { CButton, CCard, CCardBody, CSpinner } from '@coreui/react'
import { Field, Formik } from 'formik'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import FormikInput from 'src/components/common/FormikInput'
import { CHAT_TIME_DELAY } from 'src/constants'
import useInterval from 'src/hooks/useInterval'
import { reduceChatRoomIDCheck } from 'src/store/thunks/fakeAccountThunk'
import { reduceChatDetail, reduceChatSave } from 'src/store/thunks/fakeManagerThunk'
import { getGender } from 'src/utilities/common.helper'

const initQuery = {
  page: 1,
}

const ChatBoxFull = () => {
  const [searchParams] = useSearchParams()
  const [chatList, setChatList] = useState([])
  const [chatRoomId, setChatRoomId] = useState('')
  const dispatch = useDispatch()
  const [queries, setQueries] = useState(initQuery)
  const [isFirstLoading, setFirstLoading] = useState(true)
  const info = useMemo(() => JSON.parse(searchParams.get('information')), [searchParams])

  const onSubmit = async (data, { resetForm }) => {
    const params = {
      send_mem_id: info?.mem_id,
      receiverId: info?.receiverId,
      content: data.content,
      mem_id: info?.mem_id,
      sentTime: moment().unix(),
    }
    if (!data?.content) return
    const { payload } = await dispatch(reduceChatSave()(params))
    if (payload && payload.result) {
      setChatList((list) => [...list, params])
      resetForm({})
      if (!chatRoomId) {
        await checkRoomID()
      }
    } else {
      console.log('Message sending failed')
    }
  }

  const checkRoomID = async () => {
    const params = {
      send_mem_id: info?.mem_id,
      receiverId: info?.receiverId,
    }

    const { payload } = await dispatch(reduceChatRoomIDCheck()(params))
    if (payload && payload.result) {
      setChatRoomId(payload.data)
    }
  }

  const loadData = async (params) => {
    const { payload } = await dispatch(reduceChatDetail()(params))
    if (payload && payload.result && payload.data.length) {
      const list = payload.data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
      setChatList(list)
    }
    setFirstLoading(false)
  }

  const formikProps = {
    onSubmit,
    initialValues: {
      content: '',
    },
  }

  useEffect(() => {
    checkRoomID()
  }, [info])

  useInterval(async () => {
    await loadData({ ...queries, chat_room_id: chatRoomId, receiver_mem_id: info?.receiverId })
  }, CHAT_TIME_DELAY)

  return (
    <CCard className="card-full">
      <CCardBody className="card-header">
        <div>
          <b>가계정</b>: {`${info?.mem_name} | ${info?.mem_age}살 | ${getGender(info?.mem_gender)}`}
        </div>
        <div>
          <b>수신자</b>:{' '}
          {`${info?.receiverName} | ${info?.receiverAge}살 | ${getGender(info?.receiverGender)}`}
        </div>
      </CCardBody>
      <CCardBody className="card-body">
        <Formik {...formikProps}>
          {({ handleSubmit }) => (
            <>
              <CCard>
                <CCardBody className="d-flex flex-column overflow-auto chat-wrapper">
                  {isFirstLoading ? (
                    <CSpinner component="span" aria-hidden="true" className="align-self-center" />
                  ) : (
                    chatList.map((chatItem, idx) => {
                      const isMyChat = chatItem?.mem_id == info?.mem_id
                      return (
                        <div
                          key={String(idx)}
                          className={`${
                            isMyChat ? 'align-self-end' : 'align-self-start'
                          } chat-item`}
                          style={{
                            background: isMyChat ? '#e5efff' : '#fff',
                          }}
                        >
                          {chatItem.content}
                          <div
                            style={{
                              display: 'flex',
                              gap: '5px',
                              marginTop: '2px',
                              marginBottom: '-4px',
                            }}
                          >
                            <span
                              style={{
                                fontSize: '12px',
                                color: '#7b7b7b',
                              }}
                            >
                              {moment.unix(chatItem.sentTime).format('YYYY/MM/DD HH:mm')}
                            </span>
                            {isMyChat && chatItem.checked ? (
                              <span
                                style={{
                                  display: 'block',
                                  textAlign: 'right',
                                  fontSize: '12px',
                                  color: '#7b7b7b',
                                }}
                              >
                                *읽음
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      )
                    })
                  )}
                </CCardBody>
              </CCard>
              <div className="gap-2 d-flex align-items-center justify-content-between">
                <Field
                  component={FormikInput}
                  containerClassName="w-100"
                  name="content"
                  type="text"
                  disabled={isFirstLoading}
                  className="mt-3 chat-input"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit()
                    }
                  }}
                />
                <CButton className="chat-send-btn" onClick={handleSubmit}>
                  전송
                </CButton>
              </div>
            </>
          )}
        </Formik>
      </CCardBody>
    </CCard>
  )
}

export default ChatBoxFull
