import { createAsyncThunk } from '@reduxjs/toolkit'
import httpRequest from '../../api/httpRequest'

const { HOST, PORT } = {
  HOST: process.env.REACT_APP_HOST,
  PORT: process.env.REACT_APP_PORT,
}

export const baseURL = `${HOST}${PORT !== '80' ? ':' + PORT : ''}`

const _prefix = '/admin'

export const reduceFetchAdminList = createAsyncThunk(
  `${_prefix}/reduceFetchAdminList`,
  async (params, { dispatch }) => {
    const result = await httpRequest({
      baseURL,
      url: `${_prefix}-list`,
      method: 'GET',
      params,
      dispatch,
      returnError: true,
    })
    if (!result) {
      return
    }
    return result
  },
)

export const reduceFetchAdminDetail = createAsyncThunk(
  `${_prefix}/reduceFetchAdminDetail`,
  async (id, { dispatch }) => {
    const result = await httpRequest({
      baseURL,
      url: `${_prefix}-detail/${id}`,
      method: 'GET',
      dispatch,
      returnError: true,
    })
    if (!result) {
      return
    }
    return result
  },
)

export const reduceUpdateAdminDetail = createAsyncThunk(
  `${_prefix}/reduceUpdateAdminDetail`,
  async (params, { dispatch }) => {
    const result = await httpRequest({
      baseURL,
      url: `${_prefix}-modify`,
      method: 'POST',
      params,
      dispatch,
      returnError: true,
    })
    if (!result) {
      return
    }
    return result
  },
)

export const reduceAddAdminDetail = createAsyncThunk(
  `${_prefix}/reduceAddAdminDetail`,
  async (params, { dispatch }) => {
    const result = await httpRequest({
      baseURL,
      url: `${_prefix}-insert`,
      method: 'POST',
      params,
      dispatch,
      returnError: true,
    })
    if (!result) {
      return
    }
    return result
  },
)

export const reduceDeleteAdmins = createAsyncThunk(
  `${_prefix}/reduceDeleteAdmins`,
  async (data, { dispatch }) => {
    const result = await httpRequest({
      baseURL,
      url: `${_prefix}-delete`,
      method: 'POST',
      data,
      dispatch,
      returnError: true,
    })
    if (!result) {
      return
    }
    return result
  },
)

export const reduceCheckPassword = createAsyncThunk(
  `${_prefix}/reduceCheckPassword`,
  async (params, { dispatch }) => {
    const result = await httpRequest({
      baseURL,
      url: `${_prefix}-pw-check`,
      method: 'POST',
      params,
      dispatch,
      returnError: true,
    })
    if (!result) {
      return
    }
    return result
  },
)
