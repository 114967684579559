export const STORAGE_KEY = {
  TOKEN: 'token',
  REFRESH_TOKEN: 'refresh-token',
  USER: 'user',
}

export const APP_NAME = '자갸 서비스 관리 시스템'

export const BASE_ACTION = {
  GET: 'get',
  CREATE: 'create',
  EDIT: 'edit',
  DELETE: 'delete',
}

export const STATUS_LOADING = {
  IDLE: 'idle',
  PENDING: 'pending',
  ERROR: 'error',
  SUCCESS: 'success',
}

export const GENDER = {
  0: 'M',
  1: 'F',
}

export const METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
}

export const MEM_DELETED_TYPE = {
  0: '차단회원',
  1: '정상회원',
  2: '정지회원',
  4: '탈퇴 회원',
}

export const MEM_ACTIVED_TYPE = {
  1: 'N',
  2: 'Y',
}

export const MEM_ROLE_TYPE = {
  BRAND: 'Y',
  default: 'N',
}

export const MEM_PAID_TYPE = {
  0: '준회원',
  1: '정회원',
}

export const INQ_ANSWER_TYPE = {
  0: '답변',
  1: '미답변',
}

export const DEFAULT_IMAGE =
  'https://i1.wp.com/www.slntechnologies.com/wp-content/uploads/2017/08/ef3-placeholder-image.jpg?ssl=1'

export const USER_STATUS_DELETED = {
  BLOCK: 0,
  NORMAL: 1,
  SUSPENDED: 2,
}

export const DEFAULT_PAGE = {
  page: 1,
  limit: 10,
}

export const REPORT_TYPE = {
  1: 'PR룸 신고',
  2: '채팅신고',
}

export const CHAT_TIME_DELAY = 5000

//   35,000 won per month
// 67,000 won for 2 months (33,500 won * 2)
// 96,000 won for 3 months (32,000 won * 3)
// 180,000 won for 6 months (30,000 won * 6)
export const PAYMENT_AMOUNT = {
  one_month_pass: 35000,
  two_month_pass: 67000,
  three_month_pass: 96000,
  six_month_pass: 180000,
}

export const POLICY_TYPE = {
  SERVICE: '서비스 약관',
  PERSONAL: '개인 약관',
  LOCATION: '위치 약관',
}

export const BASE_ROLE = {
  ADMIN: 'ADMIN',
  USER: 'USER',
}

export const MONTH_BY_SETTLEMENT_FEE = [
  '이번달',
  ...Array.from({ length: 12 }, (_, i) => `${i + 1} 월`),
]

export const PUSH_TYPE = {
  ALL: 'ALL',
  SELECT: 'SELECT',
}

export const CHAT_HISTORY_TABLE_HEADS = ['월', '에 생성됨', '콘텐츠', '방 아이디']
