import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: false,
}

export const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setSidebarShow: (state, { payload }) => {
      state.sidebarShow = payload
    },
    setSidebarUnfoldable: (state, { payload }) => {
      state.sidebarUnfoldable = payload
    },
  },
})

// Action creators are generated for each case reducer function
// eslint-disable-next-line no-empty-pattern
export const { setSidebarShow, setSidebarUnfoldable } = slice.actions

export default slice.reducer

export const selectSidebarShow = (state) => state.app.sidebarShow
export const selectSidebarUnfoldable = (state) => state.app.sidebarUnfoldable
