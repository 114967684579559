import { combineReducers } from '@reduxjs/toolkit'
import appSlice from './slices/appSlice'
import authSlice from './slices/authSlice'
import userSlice from './slices/userSlice'

export default combineReducers({
  app: appSlice,
  auth: authSlice,
  user: userSlice,
})
