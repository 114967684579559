import { METHOD } from 'src/constants'
import { handleThunk } from 'src/utils/thunkFn'

const _prefix = '/fake'

export const reduceFakeAccountList = () =>
  handleThunk({
    name: `${_prefix}/reduceFakeAccountList`,
    httpInfo: {
      url: `${_prefix}/my-fake-list`,
    },
  })

export const reduceFakeFeeUsers = () =>
  handleThunk({
    name: `${_prefix}/reduceFakeFeeAccountList`,
    httpInfo: {
      url: `${_prefix}/fake-fee-users`,
    },
  })

export const reduceFakeAccountDetail = (id) =>
  handleThunk({
    name: `${_prefix}/reduceFakeAccountDetail`,
    httpInfo: {
      url: `${_prefix}/my-fake-detail/${id}`,
    },
  })

export const reduceGeneralList = () =>
  handleThunk({
    name: `${_prefix}/reduceGeneralList`,
    httpInfo: {
      url: `${_prefix}/general-list`,
    },
  })

export const reduceFakeAccountSave = (files) =>
  handleThunk({
    name: `${_prefix}/reduceFakeAccountSave`,
    httpInfo: {
      url: `${_prefix}/account-save`,
      method: METHOD.POST,
      data: {
        image: files?.image,
        pr_profile_file_list: files?.pr_profile_file_list,
        pr_video_file: files?.pr_video_file,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  })

export const reduceFakeAccountUpdate = (data) =>
  handleThunk({
    name: `${_prefix}/reduceFakeAccountUpdate`,
    httpInfo: {
      url: `${_prefix}/my-fake-update`,
      method: METHOD.PATCH,
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  })

export const reduceChatList = () =>
  handleThunk({
    name: `${_prefix}/reduceChatList`,
    httpInfo: {
      url: `${_prefix}/my-chat-list`,
    },
  })

export const reduceChatRoomIDCheck = () =>
  handleThunk({
    name: `${_prefix}/reduceChatRoomIDCheck`,
    httpInfo: {
      url: `${_prefix}/chat-room-id-check`,
      method: METHOD.PUT,
    },
  })

export const reduceGetPartTimeListByAlbaAccount = () =>
  handleThunk({
    name: `${_prefix}/reduceGetPartTimeListByAlbaAccount`,
    httpInfo: {
      url: `${_prefix}/other-fake-list`,
    },
  })

export const reduceMyChatList = () =>
  handleThunk({
    name: `${_prefix}/reduceMyChatList`,
    httpInfo: {
      url: `${_prefix}/my-chat-list-all`,
    },
  })

export const reduceSyncOnlineAll = () =>
  handleThunk({
    name: `${_prefix}/reduceSyncOnlineAll`,
    httpInfo: {
      url: `${_prefix}/sync-online-all`,
      method: METHOD.PUT,
    },
  })

export const reduceSetFakeOnline = (body) =>
  handleThunk({
    name: `${_prefix}/reduceSetFakeOnline`,
    httpInfo: {
      url: `${_prefix}/set-online`,
      method: METHOD.PUT,
      data: {
        memId: body.memId,
        isOnline: body.isOnline,
      },
    },
  })
