import { METHOD } from 'src/constants'
import { handleThunk } from 'src/utils/thunkFn'

const _prefix = '/user'

export const reduceUserList = () =>
  handleThunk({
    name: `${_prefix}/reduceUserList`,
    action: 'list',
    httpInfo: {
      url: `${_prefix}${_prefix}-list`,
    },
  })

export const reduceUserDetail = (id) =>
  handleThunk({
    name: `${_prefix}/reduceUserDetail`,
    httpInfo: {
      url: `${_prefix}${_prefix}-detail/${id}`,
    },
  })

export const reduceUserWithdraw = (id) =>
  handleThunk({
    name: `${_prefix}/reduceUserWithdraw`,
    httpInfo: {
      url: `${_prefix}/${id}/withdraw`,
      method: METHOD.POST,
    },
  })

export const reduceUserPause = () =>
  handleThunk({
    name: `${_prefix}/reduceUserPause`,
    httpInfo: {
      url: `${_prefix}${_prefix}-pause`,
      method: METHOD.PUT,
    },
  })

export const reduceUserPauseCancel = () =>
  handleThunk({
    name: `${_prefix}/reduceUserPauseCancel`,
    httpInfo: {
      url: `${_prefix}${_prefix}-pause-cancel`,
      method: METHOD.PUT,
    },
  })

export const reduceLocationList = () =>
  handleThunk({
    name: `${_prefix}/reduceLocationList`,
    httpInfo: {
      url: `${_prefix}/location-list`,
    },
  })

export const reduceCheckRole = () =>
  handleThunk({
    name: `${_prefix}/reduceCheckRole`,
    httpInfo: {
      url: `${_prefix}/get-mem-role`,
    },
  })
