import { uniq } from 'lodash'

export function getGender(gender) {
  if (typeof gender === 'string') return gender === 'M' ? '남성' : '여성'
  if (typeof gender === 'number') return gender === 0 ? '남성' : '여성'
  return null
}

export const getAge = (dob) => {
  if (!dob) return null
  return new Date().getFullYear() - +dob
}

export const parseLocationString = (locations) =>
  uniq(locations?.map((l) => l.location_name))?.join(', ')

export const stringToBool = (value) => (value === 'null' ? null : value === 'true' ? true : false)
