import { createSlice } from '@reduxjs/toolkit'
import {
  reduceUserList,
  reduceUserDetail,
  reduceLocationList,
  reduceCheckRole,
} from '../thunks/userThunk'

const initialState = {
  users: [],
  user: null,
  page: {},
  locationList: [],
  userRoles: null,
}

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(reduceUserList().fulfilled, (state, action) => {
        state.users = action.payload?.data
        state.page = action.payload?.page
      })
      .addCase(reduceUserDetail().fulfilled, (state, action) => {
        state.user = action.payload?.data
      })
      .addCase(reduceLocationList().fulfilled, (state, action) => {
        state.locationList = action.payload.data
      })
      .addCase(reduceCheckRole().fulfilled, (state, action) => {
        state.userRoles = action.payload.replace(/[[\]']+/g, '')
      })
  },
})

// export const { fetchUsers } = slice.actions

export default slice.reducer

export const selectLocationList = (state) => {
  return state.user.locationList
}

export const getLocationOptions = (state) => {
  // console.log(state.user.locationList.filter((l) => !l._all_option))
  return state.user.locationList
    .filter((l) => !l.all_option)
    .map((locationItem) => ({
      label: locationItem?.location_name,
      value: locationItem?.location_id,
    }))
}

export const getNameLocationById = (list, id) => {
  return (list || []).find((locationItem) => locationItem?.value == id)?.label
}

export const selectUserRoles = (state) => {
  return state.user.userRoles
}
