import axios from 'axios'
import StorageHelper from '../utilities/StorageHelper'
import { STORAGE_KEY } from '../constants'
import { logout } from 'src/store/thunks/authThunk'

const { HOST, PORT, API_VERSION } = {
  HOST: process.env.REACT_APP_HOST,
  PORT: process.env.REACT_APP_PORT,
  API_VERSION: process.env.REACT_APP_API_VERSION,
}

export const baseURL = `${HOST}${PORT !== '80' ? ':' + PORT : ''}/api/${API_VERSION}`

const instance = axios.create({
  baseURL,
  headers: { Accept: 'application/json' },
})

instance.interceptors.request.use(async (config) => {
  // console.log(JSON.stringify(config));
  // const token = StorageHelper.getCookie(STORAGE_KEY.TOKEN)
  // if (token) {
  //   config.headers.Authorization = 'Bearer ' + token
  // }
  return config
})

instance.interceptors.response.use(
  (response) => {
    // if (response && response.data) {
    //   return response.data;
    // }
    return response
  },
  (error) => {
    // Handle errors
    if (error.response.status === 401 || error.response.status === 403) {
      // AsyncStorageUtils.clearStorage();
      logout()
      // Todo: Remove current user info state
    }
    if (error.response.data) throw error.response.data
    throw error
  },
)

export default instance
