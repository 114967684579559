import { createAsyncThunk } from '@reduxjs/toolkit'
import httpRequest from 'src/api/httpRequest'
import { METHOD } from 'src/constants'

export const handleParams = ({ url, method = METHOD.GET, ...rest }) => {
  return {
    url,
    method,
    returnError: true,
    withCredentials: true,
    ...rest,
  }
}

export const handleThunk = ({ name, url, httpInfo = {} }) => {
  return createAsyncThunk(name, async (params, { dispatch }) => {
    const requesInfo = handleParams({
      url,
      params,
      dispatch,
      ...httpInfo,
    })
    const result = await httpRequest(requesInfo)

    if (!result) {
      return
    }
    if (result?.result) {
      return result
    }
    return result
  })
}
