import { createSlice } from '@reduxjs/toolkit'
import { reduceUserLogin } from '../thunks/authThunk'
import StorageHelper from '../../utilities/StorageHelper'
import { STORAGE_KEY } from '../../constants'

const initialState = {
  authInfo: null,
}

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthInfo: (state, { payload }) => {
      state.authInfo = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reduceUserLogin.fulfilled, (state, { payload }) => {
      if (payload.result) {
        const user = {
          mem_id: payload.data,
        }
        state.authInfo = user
        StorageHelper.setLocalObject(STORAGE_KEY.USER, user)
      }
    })
  },
})

// Action creators are generated for each case reducer function
// eslint-disable-next-line no-empty-pattern
export const { setAuthInfo } = slice.actions

export default slice.reducer

export const selectAuthInfo = (state) =>
  state.auth.authInfo || StorageHelper.getLocalObject(STORAGE_KEY.USER) || {}
