import React, { useEffect, useRef } from 'react'

const useInterval = (callback, delay) => {
  const saveCallback = useRef()

  const tick = () => {
    saveCallback.current()
  }

  useEffect(() => {
    saveCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearTimeout(id)
    }
  }, [delay])
}

export default useInterval
