import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import LoadingPage from '../views/pages/common/LoadingPage'
import StorageHelper from '../utilities/StorageHelper'
import { STORAGE_KEY } from '../constants'
import { reduceCheckRole } from 'src/store/thunks/userThunk'

const ProtectedRoute = ({ redirectPath = '/login', children }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = React.useState(true)
  const [isLoggedIn, setIsLoggedIn] = React.useState(false)

  const restoreToken = async () => {
    const token = StorageHelper.getSessionItem(STORAGE_KEY.TOKEN)
    if (token) {
      getUserRoles()
    }
    setIsLoggedIn(!!token)
  }

  const getUserRoles = async () => {
    await dispatch(reduceCheckRole()())
  }

  React.useEffect(() => {
    restoreToken().then(() => setLoading(false))
  }, [])

  if (loading) {
    return <LoadingPage />
  }

  if (!isLoggedIn) {
    return <Navigate to={redirectPath} replace />
  }

  return children ? children : <Outlet />
}

export default ProtectedRoute
