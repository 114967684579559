import React from 'react'
import { CSpinner } from '@coreui/react'

const LoadingPage = () => {
  return (
    <div className="vh-100 align-items-center justify-content-center d-flex">
      <div className="text-center">
        <CSpinner variant="grow" />
        <p className="mt-3">Loading...</p>
      </div>
    </div>
  )
}

export default LoadingPage
