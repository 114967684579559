import { createAsyncThunk } from '@reduxjs/toolkit'
import StorageHelper from '../../utilities/StorageHelper'
import { STORAGE_KEY } from '../../constants'
import httpRequest from '../../api/httpRequest'
import { baseURL } from '../thunks/adminThunk'

const _prefix = '/user'

export const reduceUserLogin = createAsyncThunk(
  `${_prefix}/reduceUserLogin`,
  async (params, { dispatch }) => {
    const result = await httpRequest({
      baseURL,
      url: '/login',
      method: 'POST',
      params,
      dispatch,
      returnError: true,
      withCredentials: true,
    })
    if (!result) {
      return
    }
    if (result?.result) {
      StorageHelper.setSessionItem(STORAGE_KEY.TOKEN, new Date().getTime().toString())
    }
    return result
  },
)

export const logout = () => {
  StorageHelper.removeCookie(STORAGE_KEY.TOKEN)
  localStorage.clear()
  sessionStorage.clear()
  window.location.href = '/'
}
