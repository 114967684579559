import React, { useContext, useEffect, useRef, useState } from 'react'
import { CToast, CToastBody, CToastClose, CToaster } from '@coreui/react'

export const ToastContext = React.createContext()

export function useToast() {
  const { addToast } = useContext(ToastContext)

  return (message, options = {}) => {
    const { color } = options
    const toast = (
      <CToast color={color} className={`align-items-center${color ? ' text-white' : ''}`}>
        <div className="d-flex">
          <CToastBody>{message}</CToastBody>
          <CToastClose className="me-2 m-auto" white={!!color} />
        </div>
      </CToast>
    )
    return addToast(toast)
  }
}

export const ToastProvider = ({ children }) => {
  const [toast, addToast] = useState(0)
  const toaster = useRef()

  return (
    <ToastContext.Provider
      value={{
        toast,
        addToast,
      }}
    >
      {children}
      <CToaster ref={toaster} push={toast} placement="top-end" />
    </ToastContext.Provider>
  )
}
