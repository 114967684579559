import { METHOD } from 'src/constants'
import { handleThunk } from 'src/utils/thunkFn'

const _prefix = '/fake-manager'

export const reduceCommissionHistory = () =>
  handleThunk({
    name: `${_prefix}/reduceCommissionHistory`,
    httpInfo: {
      url: `${_prefix}/commission-history`,
    },
  })

export const reduceCommissionSave = () =>
  handleThunk({
    name: `${_prefix}/reduceCommissionSave`,
    httpInfo: {
      url: `${_prefix}/commission-save`,
      method: METHOD.POST,
    },
  })

export const reduceFakeParentSave = () =>
  handleThunk({
    name: `${_prefix}/reduceFakeParentSave`,
    httpInfo: {
      url: `${_prefix}/fake--parent-save`,
      method: METHOD.POST,
    },
  })

export const reduceFakeDelete = () =>
  handleThunk({
    name: `${_prefix}/reduceFakeDelete`,
    httpInfo: {
      url: `${_prefix}/fake-delete`,
      method: METHOD.PUT,
    },
  })

export const reduceFakeDetail = () =>
  handleThunk({
    name: `${_prefix}/reduceFakeDetail`,
    httpInfo: {
      url: `${_prefix}/fake-detail`,
    },
  })

export const reduceFakeList = () =>
  handleThunk({
    name: `${_prefix}/reduceFakeList`,
    httpInfo: {
      url: `${_prefix}/fake-list`,
    },
  })

export const reduceFakeModify = () =>
  handleThunk({
    name: `${_prefix}/reduceFakeModify`,
    httpInfo: {
      url: `${_prefix}/fake-modify`,
      method: METHOD.PUT,
    },
  })

export const reduceMemIdCheck = () =>
  handleThunk({
    name: `${_prefix}/reduceMemIdCheck`,
    httpInfo: {
      url: `${_prefix}/mem-id-check`,
    },
  })

export const reduceMyCommissionList = () =>
  handleThunk({
    name: `${_prefix}/reduceCommissionList`,
    httpInfo: {
      url: `${_prefix}/my-commission-list`,
      method: METHOD.PUT,
    },
  })

export const reduceFakeChatList = () =>
  handleThunk({
    name: `${_prefix}/reduceFakeChatList`,
    httpInfo: {
      url: `${_prefix}/fake-chat-list`,
    },
  })

export const reduceChatSave = () =>
  handleThunk({
    name: `${_prefix}/reduceChatSave`,
    httpInfo: {
      url: `${_prefix}/chat-save`,
      method: METHOD.PUT,
    },
  })

export const reduceChatDetail = () =>
  handleThunk({
    name: `${_prefix}/reduceChatDetail`,
    httpInfo: {
      url: `${_prefix}/chat-detail`,
    },
  })
